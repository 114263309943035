import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { sBitIcon } from "../../Assets/Data"

import "./style.css"


//components
//import SideFloatingBar from "../../Components/SideFloatingBar"
import HomeNav from "../Home/HomeNav"
import Footer from "../../Components/Footer"

//images

import icoeth from "../../Assets/img/ico-eth.png"
import icotcn from "../../Assets/img/ico-tcn.png"
import icokus from '../../Assets/img/home-ico-3-1.png';
import ETHI from "../../Assets/Images/E4bc3598cd3721b16fa31a60ab91c0b04.png";
import USDT from "../../Assets/Images/USDT icon.png"
import USDC from "../../Assets/Images/USDC.png"
import BUSD from "../../Assets/Images/BUSD.png"
import MATIC from "../../Assets/Images/MATIC.png"
import BNB from "../../Assets/Images/BNB.png";


export default function TokenSale() {
  const [sideNav, setSideNav] = useState(false)

  return (
    <>
      <div className="s_web_wrapper">
        <div className="s_hm_bg_line_token"></div>
        <div className="s_web_wrapper_in">
          <HomeNav setSideNav={setSideNav} sideNav={sideNav} />
          <section className="ts_mid_sec">
            <div className="container tokemInnerContainer">
              <h1 className="srk_fw_700 ts_hm1_sec_hdng_token w-100 text-center srk_mb_2dot5">Migrate And Vest KUS to KUSD</h1>
              <div className="row g-5">
                <div className="col-md-7">
                  <div className="ts_comn_pnl tokemInputMainBox">
                    <div className="srk srk_fs_1dot5 srk_fw_600 srk_clr_white_1 srk_mb_1 w-100 text-center">Migrate Burn And Vest</div>
                    <div className="srk srk_fs_1 srk_fw_300 srk_clr_white_1 srk_mb_2dot6 w-100 text-center">
                      Burn your KUS, get double the value in KUSD and vest for 3 months
                    </div>
                    <div className="ts_input_set ">
                      <div className="ts_input_lbl">
                        <img src={icokus} alt="" />
                        <span className="ts_input_lbl_select">KUS:</span>
                      </div>
                      <input type="number" name="" id="" className="ts_input_box" placeholder="0.00" />
                    </div>
                    <div className="ts_input_set ">
                      <div className="ts_input_lbl">
                        <img src={icokus} alt="" />
                        <span className="ts_input_lbl_select" style={{backgroundColor: 'inherit'}}>KUSD:</span>
                      </div>
                      <input type="number" name="" id="" className="ts_input_box" placeholder="0.00" />
                    </div>
                    <div className="row justify-content-center srk_mt_2dot6">
                      <div className="col-7 connectWalBtn">
                        <a href="#" className="ts_btn_outline w-100">Connect Wallet</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ts_tok_lineset_out">
                  <div className="srk srk_fs_1dot5 srk_fw_700 srk_clr_white_1 srk_mb_2 mt-lg-5 mt-md-4">Your Stake</div>
                  <div className="ts_tok_lineset">
                    <div className="row">
                      <div className="col-7">
                        <div className="srk srk_fs_1dot1 srk_clr_white_1 ">Vested</div>
                      </div>
                      <div className="col-5">
                        <div className="srk srk_fs_1dot1 srk_fw_700 srk_clr_white_1 w-100 text-end">0.00</div>
                      </div>
                    </div>
                  </div>
                  <div className="ts_tok_lineset">
                    <div className="row">
                      <div className="col-7">
                        <div className="srk srk_fs_1dot1 srk_clr_white_1 ">Lock Time</div>
                      </div>
                      <div className="col-5">
                        <div className="srk srk_fs_1dot1 srk_fw_700 srk_clr_white_1 w-100 text-end">0.00</div>
                      </div>
                    </div>
                  </div>
                  <div className="ts_tok_lineset">
                    <div className="row">
                      <div className="col-7">
                        <div className="srk srk_fs_1dot1 srk_clr_white_1 ">Sold Token</div>
                      </div>
                      <div className="col-5">
                        <div className="srk srk_fs_1dot1 srk_fw_700 srk_clr_white_1 w-100 text-end">0.00</div>
                      </div>
                    </div>
                  </div>
                  <div className="ts_tok_lineset">
                    <div className="row">
                      <div className="col-7">
                        <div className="srk srk_fs_1dot1 srk_clr_white_1 ">KUSD Address</div>
                      </div>
                      <div className="col-5">
                        <div className="srk srk_fs_1dot1 srk_fw_700 srk_clr_white_1 w-100 text-end">0x89757</div>
                      </div>
                    </div>
                  </div>
                  <div className="ts_tok_lineset">
                    <div className="row">
                      <div className="col-7">
                        <div className="srk srk_fs_1dot1 srk_clr_white_1 ">KUS Address</div>
                      </div>
                      <div className="col-5">
                        <div className="srk srk_fs_1dot1 srk_fw_700 srk_clr_white_1 w-100 text-end">0x61...f0c73</div>
                      </div>
                    </div>
                  </div>
                  <div className="srk srk_fs_1dot5 srk_fw_700 srk_clr_white_1 srk_mt_2dot9 srk_mb_1dot4">What's KUSDollar?</div>
                  <div className="kusd-description">
                    <p className='text-light'>
                      What is KusDollar, and why are we transitioning from Kus Token? 
                      Every crypto project experiences its ups and downs, and sometimes they don't recover from the lows. 
                      KusDollar is our solution. 
                      We are offering you a very special opportunity to invest in a stablecoin backed by assets from both within and outside the crypto world. 
                      Simultaneously, you can profit from our new social DEX platform, KuskuSwap. 
                      Seventy percent of the profits from our platform will be distributed to our investors through staking. 
                      Essentially, this is the closest way you can have your cake and eat it too, leaving it untouched.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ textDecoration: "underline" }} className="ts_bot_sec">Need Help ? Join Us At our <a href="#">Telegram</a> Community</section>
          <Footer />
        </div>
      </div>
    </>
  )
}
