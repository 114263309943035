export const NavIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="56"
    viewBox="0 0 80 56"
    fill="none"
  >
    <g clip-path="url(#clip0_350_6583)">
      <path
        d="M31.4043 37.693C33.2869 37.0673 34.4978 35.7108 35.3933 34.0392C36.5722 31.8286 37.0657 29.4308 37.3627 26.969C37.6917 24.288 38.3451 21.6709 39.7388 19.3462C41.2618 16.882 43.3007 14.7769 45.7155 13.1758C47.8015 11.6818 50.2315 10.7392 52.7797 10.4354C54.5122 10.3085 56.254 10.43 57.9522 10.7962C61.7447 11.495 65.19 12.9885 68.0367 15.6193C71.9207 19.2001 73.6433 23.7034 73.4285 28.9603C73.3367 31.1486 72.8923 33.3077 72.1126 35.3545C72.0212 35.6058 71.9389 35.8113 71.5962 35.8113C68.745 35.8113 65.8937 35.8113 63.047 35.8113C62.9822 35.7998 62.9192 35.7799 62.8596 35.7519C63.1749 35.4596 63.4811 35.213 63.7415 34.9252C64.6785 33.816 65.2596 32.4505 65.4093 31.0065C65.8793 28.4178 65.4977 25.7466 64.3218 23.3928C62.951 20.7209 60.6664 19.2823 57.806 18.7251C55.4593 18.3078 53.0505 18.3981 50.7417 18.99C50.3192 19.1052 49.9079 19.2581 49.5126 19.4467C49.3946 19.518 49.3946 19.518 49.2978 19.5746C49.2076 19.6273 49.2076 19.6302 49.0581 19.6676C48.6087 19.7475 48.213 19.992 47.8813 20.3053C44.9387 22.3743 42.8002 25.1512 41.073 28.2524C39.5697 30.9471 37.9156 33.5094 35.5122 35.4961C34.3104 36.4507 33.0081 37.3596 31.4043 37.693Z"
        fill="url(#paint0_linear_350_6583)"
      />
      <path
        d="M31.4057 37.6943C33.0095 37.3609 34.3118 36.452 35.5181 35.438C37.9216 33.4513 39.5757 30.8707 41.079 28.1943C42.8062 25.0931 44.9202 22.2613 47.8629 20.1923C48.4612 19.7717 48.8719 19.6366 49.3212 19.5566C46.4562 21.9225 45.2691 25.2895 44.8076 28.7789C44.4237 31.6198 43.8571 34.4013 42.5229 36.9453C41.3577 39.1376 39.6214 40.8777 37.7297 42.426C35.5476 44.3059 32.91 45.58 30.0806 46.1209C28.4114 46.3628 26.7141 46.3335 25.0543 46.0342C21.2615 45.566 17.6752 44.0473 14.7002 41.6496C12.8965 40.2079 11.4216 38.3979 10.3744 36.3405C9.32708 34.2831 8.73152 32.0258 8.62753 29.7198C8.45116 26.85 8.89531 23.976 9.92978 21.2931C10.0212 21.0465 10.0988 20.8364 10.4461 20.8364C13.3476 20.8364 16.2492 20.8364 19.1736 20.8364C19.1736 20.9141 19.1736 20.9826 19.1187 21.0054C17.355 22.2842 16.7884 24.1705 16.5553 26.1893C16.1579 28.8378 16.6752 31.5426 18.0221 33.8578C19.5345 36.3743 21.874 37.6121 24.6979 38.0414C26.9359 38.3447 29.2109 38.227 31.4057 37.6943Z"
        fill="url(#paint1_linear_350_6583)"
      />
      <path
        d="M61.4302 36.1465C61.4302 38.9919 61.4302 41.746 61.4302 44.5001C61.4302 44.8061 61.2475 44.902 61.0236 45.0024C58.7353 46.0546 56.229 46.5463 53.7126 46.4366C50.5725 46.2894 47.558 45.1598 45.0948 43.2075C44.8253 43.002 44.7978 42.8924 45.0446 42.632C46.659 40.9087 47.8441 38.8291 48.5036 36.5621C48.6132 36.2058 48.6726 36.1054 49.029 36.3383C53.0729 38.8275 57.1122 39.0147 61.1287 36.2744C61.2064 36.2515 61.2749 36.215 61.4302 36.1465Z"
        fill="url(#paint2_linear_350_6583)"
      />
      <path
        d="M20.3066 20.4203C20.3066 17.5657 20.3066 14.8071 20.3066 12.053C20.3066 11.7104 20.526 11.6236 20.7636 11.5186C22.9319 10.5306 25.2945 10.042 27.677 10.089C30.916 10.1648 34.0434 11.2864 36.5918 13.2861C36.8979 13.5191 36.9299 13.6424 36.6512 13.9393C35.056 15.6584 33.8842 17.7259 33.2287 19.9772C33.1237 20.3243 33.0597 20.434 32.6987 20.201C28.6777 17.7118 24.6521 17.5246 20.6356 20.233C20.5397 20.2924 20.4574 20.3289 20.3066 20.4203Z"
        fill="url(#paint3_linear_350_6583)"
      />
      <path
        d="M67.8268 45.5409C66.753 45.5409 65.6746 45.5409 64.6008 45.5409C64.2993 45.5409 64.185 45.4678 64.1896 45.1435C64.1896 42.9558 64.1896 40.768 64.1896 38.5803C64.1896 38.2652 64.2855 38.1738 64.5963 38.1738C66.7835 38.1738 68.9722 38.1738 71.1624 38.1738C71.4411 38.1738 71.5508 38.2469 71.5462 38.5392C71.5462 40.7452 71.5462 42.9512 71.5462 45.1618C71.5462 45.5089 71.3817 45.5363 71.0893 45.5317C70.0384 45.5363 68.9417 45.5409 67.8268 45.5409Z"
        fill="url(#paint4_linear_350_6583)"
      />
      <path
        d="M14.0423 18.3286C12.9822 18.3286 11.9221 18.3286 10.8666 18.3286C10.501 18.3286 10.3594 18.2601 10.3594 17.8719C10.3594 15.7207 10.3594 13.565 10.3594 11.4138C10.3594 11.1123 10.3959 10.957 10.7706 10.957C12.9243 10.9783 15.0765 10.9783 17.2271 10.957C17.6155 10.957 17.7206 11.0712 17.716 11.4503C17.716 13.5832 17.716 15.7162 17.716 17.8445C17.716 18.251 17.5698 18.3286 17.2043 18.3012C16.1579 18.3149 15.0978 18.3286 14.0423 18.3286Z"
        fill="url(#paint5_linear_350_6583)"
      />
      <path
        d="M10.1174 3.01854C10.9764 3.01854 11.8354 3.01854 12.6899 3.01854C12.996 3.01854 13.1011 3.10075 13.1011 3.42046C13.1011 5.13472 13.1011 6.84898 13.1011 8.56324C13.1011 8.86925 13.0143 8.97431 12.699 8.97431C10.987 8.97431 9.27353 8.97431 7.55851 8.97431C7.25236 8.97431 7.13814 8.87838 7.14271 8.56324C7.14271 6.85203 7.14271 5.13929 7.14271 3.42503C7.14271 3.10989 7.24782 3.01397 7.55396 3.01854C8.42671 3.02767 9.26289 3.01397 10.1174 3.01854Z"
        fill="url(#paint6_linear_350_6583)"
      />
      <path
        d="M71.5703 53.3008C70.7296 53.3008 69.8934 53.3008 69.0526 53.3008C68.7419 53.3008 68.5957 53.2414 68.5957 52.8851C68.5957 51.1907 68.5957 49.4916 68.5957 47.7972C68.5957 47.4638 68.6917 47.3633 69.0298 47.3633C70.725 47.3633 72.4203 47.3633 74.12 47.3633C74.4079 47.3633 74.5404 47.4181 74.5358 47.7469C74.5358 49.4597 74.5358 51.1724 74.5358 52.8851C74.5358 53.2551 74.3851 53.3008 74.0789 53.2962C73.2473 53.2916 72.3974 53.3008 71.5703 53.3008Z"
        fill="url(#paint7_linear_350_6583)"
      />
      <path
        d="M3.91505 15.8713C3.27992 15.8713 2.64021 15.8713 2.0005 15.8713C1.72177 15.8713 1.61667 15.7799 1.62124 15.4922C1.62124 14.2316 1.62124 12.9756 1.62124 11.7196C1.62124 11.441 1.70349 11.3359 1.99593 11.3359C3.25402 11.3359 4.51059 11.3359 5.76563 11.3359C6.04436 11.3359 6.15859 11.4182 6.15859 11.7105C6.15859 12.9665 6.15859 14.227 6.15859 15.483C6.15859 15.8165 6.00323 15.8758 5.70165 15.8713C5.12135 15.8621 4.51821 15.8713 3.91505 15.8713Z"
        fill="url(#paint8_linear_350_6583)"
      />
      <path
        d="M77.8195 41.0092C78.4227 41.0092 79.0213 41.0092 79.6473 41.0092C79.9306 41.0092 80.0265 41.0869 80.0219 41.3792C80.0219 42.6352 80.0219 43.8957 80.0219 45.1517C80.0219 45.3984 79.9762 45.5126 79.6929 45.508C78.4181 45.508 77.1433 45.508 75.8639 45.508C75.5531 45.508 75.5074 45.3755 75.512 45.1152C75.512 43.8729 75.512 42.6352 75.512 41.3974C75.512 41.0686 75.6354 40.9955 75.9324 41.0046C76.5401 41.0183 77.1798 41.0092 77.8195 41.0092Z"
        fill="url(#paint9_linear_350_6583)"
      />
      <path
        d="M3.29464 1.63988C3.29464 2.06007 3.29464 2.48027 3.29464 2.90046C3.29464 3.16536 3.23979 3.30238 2.93365 3.29781C2.07461 3.29781 1.21557 3.29781 0.361105 3.29781C0.105222 3.29781 -0.0044325 3.22474 0.000136837 2.95983C0.000136837 2.08291 0.000136837 1.20599 0.000136837 0.32907C0.000136837 0.0550324 0.11436 -0.00432904 0.361105 0.000238257C1.22014 0.000238257 2.07461 0.000238257 2.93365 0.000238257C3.22608 0.000238257 3.3129 0.100726 3.30376 0.379331C3.28091 0.799522 3.29464 1.21969 3.29464 1.63988Z"
        fill="url(#paint10_linear_350_6583)"
      />
      <path
        d="M63.1257 55.78C62.7281 55.78 62.326 55.78 61.9239 55.78C61.5218 55.78 61.435 55.7206 61.467 55.3233C61.4944 54.5057 61.4898 53.6882 61.467 52.8706C61.467 52.5555 61.5538 52.4641 61.8645 52.4733C62.7007 52.4733 63.5415 52.4733 64.3776 52.4733C64.6518 52.4733 64.7661 52.5463 64.7569 52.8386C64.7569 53.679 64.7569 54.5148 64.7569 55.3507C64.7569 55.6612 64.6518 55.7709 64.3457 55.7572C63.9299 55.7663 63.5278 55.78 63.1257 55.78Z"
        fill="url(#paint11_linear_350_6583)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_350_6583"
        x1="52.4253"
        y1="10.3867"
        x2="67.5262"
        y2="37.4346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_350_6583"
        x1="28.9555"
        y1="19.5566"
        x2="28.9555"
        y2="46.2828"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_350_6583"
        x1="48.8074"
        y1="34.5023"
        x2="61.6536"
        y2="46.9793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_350_6583"
        x1="28.5744"
        y1="10.0859"
        x2="28.5744"
        y2="20.4203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_350_6583"
        x1="62.3848"
        y1="38.1721"
        x2="69.7259"
        y2="47.7138"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_350_6583"
        x1="11.0099"
        y1="11.0112"
        x2="17.6169"
        y2="20.553"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_350_6583"
        x1="8.80818"
        y1="2.93583"
        x2="12.4789"
        y2="9.90899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_350_6583"
        x1="67.1567"
        y1="46.614"
        x2="77.0667"
        y2="56.5223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_350_6583"
        x1="3.88984"
        y1="11.3359"
        x2="3.88984"
        y2="15.8715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_350_6583"
        x1="74.8615"
        y1="40.7405"
        x2="81.8352"
        y2="47.713"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_350_6583"
        x1="1.6522"
        y1="0"
        x2="1.6522"
        y2="3.29792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#68FFFB" />
        <stop offset="1" stop-color="#5BB8CD" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_350_6583"
        x1="60.1837"
        y1="52.1187"
        x2="65.3222"
        y2="57.2564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BD69FF" />
        <stop offset="1" stop-color="#6905B8" />
      </linearGradient>
      <clipPath id="clip0_350_6583">
        <rect width="80" height="55.7895" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BitIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <g clip-path="url(#clip0_350_6619)">
      <path
        d="M19.5307 19.3088L19.5347 19.3035C19.4114 19.659 19.179 19.9665 18.8707 20.1821C18.512 20.4341 18.008 20.6328 17.2867 20.6675L14.6333 20.6728V16.7661C15.0173 16.7768 16.72 16.7488 17.4587 16.7861C18.4213 16.8328 19.02 17.2141 19.3387 17.6768C19.688 18.1448 19.7293 18.7701 19.5307 19.3088ZM16.692 14.9608C17.3627 14.9288 17.828 14.7448 18.16 14.5128C18.4453 14.3181 18.66 14.0235 18.7733 13.6981C18.952 13.1981 18.916 12.6181 18.592 12.1848C18.2947 11.7595 17.7413 11.4061 16.8507 11.3635C16.1907 11.3288 14.9667 11.3595 14.632 11.3501V14.9621C14.6653 14.9675 16.684 14.9608 16.692 14.9608ZM32 16.0781C32 24.9141 24.836 32.0781 16 32.0781C7.164 32.0781 0 24.9141 0 16.0781C0 7.24213 7.164 0.078125 16 0.078125C24.836 0.078125 32 7.24213 32 16.0781ZM23.1333 18.9341C23.1627 16.9648 21.48 15.8248 20.5893 15.5048C21.5427 14.8501 22.1973 14.1141 22.1973 12.7248C22.1973 10.5181 20.0027 9.48479 18.668 9.35279C18.5587 9.34079 18.2547 9.33546 18.2547 9.33546V7.22613H16.0773L16.0827 9.33679H14.6267V7.23413H12.4133V9.32613C12.024 9.33546 9.68133 9.33413 9.68133 9.33413V11.2021H10.4427C11.244 11.2235 11.5387 11.6848 11.5067 12.1048V20.1595C11.5017 20.2916 11.4487 20.4175 11.3577 20.5134C11.2666 20.6094 11.1437 20.6689 11.012 20.6808C10.68 20.6955 10.184 20.6808 10.184 20.6808L9.75733 22.7981H12.4187V24.9315H14.6333V22.8101H16.088V24.9355H18.2533V22.8181C20.7853 22.8848 23.1107 21.3901 23.1333 18.9341Z"
        fill="#22BBC8"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_6619">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.078125)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const sBitIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_350_6653)">
      <path
        d="M14.648 14.423L14.651 14.419C14.5585 14.6856 14.3843 14.9163 14.153 15.078C13.884 15.267 13.506 15.416 12.965 15.442L10.975 15.446V12.516C11.263 12.524 12.54 12.503 13.094 12.531C13.816 12.566 14.265 12.852 14.504 13.199C14.766 13.55 14.797 14.019 14.648 14.423ZM12.519 11.162C13.022 11.138 13.371 11 13.62 10.826C13.834 10.68 13.995 10.459 14.08 10.215C14.214 9.84 14.187 9.405 13.944 9.08C13.721 8.761 13.306 8.496 12.638 8.464C12.143 8.438 11.225 8.461 10.974 8.454V11.163C10.999 11.167 12.513 11.162 12.519 11.162ZM24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12ZM17.35 14.142C17.372 12.665 16.11 11.81 15.442 11.57C16.157 11.079 16.648 10.527 16.648 9.485C16.648 7.83 15.002 7.055 14.001 6.956C13.919 6.947 13.691 6.943 13.691 6.943V5.361H12.058L12.062 6.944H10.97V5.367H9.31V6.936C9.018 6.943 7.261 6.942 7.261 6.942V8.343H7.832C8.433 8.359 8.654 8.705 8.63 9.02V15.061C8.62627 15.1601 8.58654 15.2545 8.51826 15.3265C8.44997 15.3985 8.35781 15.4431 8.259 15.452C8.01 15.463 7.638 15.452 7.638 15.452L7.318 17.04H9.314V18.64H10.975V17.049H12.066V18.643H13.69V17.055C15.589 17.105 17.333 15.984 17.35 14.142Z"
        fill="#22BBC8"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_6653">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const redTriangle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
  >
    <path d="M6.72 9L14 0H0L6.72 9Z" fill="#FF7D61" />
  </svg>
);
export const I_icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g opacity="0.5">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM11.5 6C11.2348 6 10.9804 6.10536 10.7929 6.29289C10.6054 6.48043 10.5 6.73478 10.5 7C10.5 7.26522 10.6054 7.51957 10.7929 7.70711C10.9804 7.89464 11.2348 8 11.5 8H12C12.2652 8 12.5196 7.89464 12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6H11.5ZM10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12H11V15H10C9.73478 15 9.48043 15.1054 9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071C9.48043 16.8946 9.73478 17 10 17H14C14.2652 17 14.5196 16.8946 14.7071 16.7071C14.8946 16.5196 15 16.2652 15 16C15 15.7348 14.8946 15.4804 14.7071 15.2929C14.5196 15.1054 14.2652 15 14 15H13V11C13 10.7348 12.8946 10.4804 12.7071 10.2929C12.5196 10.1054 12.2652 10 12 10H10Z"
        fill="white"
      />
    </g>
  </svg>
);
export const sWhiteBitIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <g clip-path="url(#clip0_406_3011)">
      <path
        d="M9.52803 10.1153L9.53003 10.1127C9.46838 10.2904 9.3522 10.4442 9.19803 10.552C9.0187 10.678 8.7667 10.7773 8.40603 10.7947L7.07936 10.7973V8.844C7.27136 8.84933 8.1227 8.83533 8.49203 8.854C8.97336 8.87733 9.2727 9.068 9.43203 9.29933C9.6067 9.53333 9.62736 9.846 9.52803 10.1153ZM8.1087 7.94133C8.44403 7.92533 8.6767 7.83333 8.8427 7.71733C8.98536 7.62 9.0927 7.47267 9.14936 7.31C9.2387 7.06 9.2207 6.77 9.0587 6.55333C8.91003 6.34067 8.63336 6.164 8.18803 6.14267C7.85803 6.12533 7.24603 6.14067 7.0787 6.136V7.942C7.09536 7.94467 8.1047 7.94133 8.1087 7.94133ZM15.7627 8.5C15.7627 12.918 12.1807 16.5 7.7627 16.5C3.3447 16.5 -0.237305 12.918 -0.237305 8.5C-0.237305 4.082 3.3447 0.5 7.7627 0.5C12.1807 0.5 15.7627 4.082 15.7627 8.5ZM11.3294 9.928C11.344 8.94333 10.5027 8.37333 10.0574 8.21333C10.534 7.886 10.8614 7.518 10.8614 6.82333C10.8614 5.72 9.76403 5.20333 9.0967 5.13733C9.04203 5.13133 8.89003 5.12867 8.89003 5.12867V4.074H7.80136L7.80403 5.12933H7.07603V4.078H5.96936V5.124C5.7747 5.12867 4.60336 5.128 4.60336 5.128V6.062H4.98403C5.3847 6.07267 5.53203 6.30333 5.51603 6.51333V10.5407C5.51354 10.6068 5.48706 10.6697 5.44153 10.7177C5.39601 10.7656 5.33457 10.7954 5.2687 10.8013C5.1027 10.8087 4.8547 10.8013 4.8547 10.8013L4.64136 11.86H5.97203V12.9267H7.07936V11.866H7.8067V12.9287H8.88936V11.87C10.1554 11.9033 11.318 11.156 11.3294 9.928Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_406_3011">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CheckboxTik = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_350_2667)">
      <path
        opacity="0.3"
        d="M4.77331 11.0874C4.5711 10.9617 4.42714 10.7607 4.37309 10.5288C4.31904 10.2969 4.35934 10.053 4.48511 9.85076L10.1381 0.762443C10.2638 0.56023 10.4648 0.416265 10.6967 0.362216C10.9286 0.308168 11.1725 0.348464 11.3747 0.474241C11.577 0.600017 11.7209 0.800971 11.775 1.03289C11.829 1.26482 11.7887 1.50871 11.6629 1.71092L6.01 10.7992C5.88422 11.0015 5.68327 11.1454 5.45134 11.1995C5.21942 11.2535 4.97553 11.2132 4.77331 11.0874Z"
        fill="white"
      />
      <path
        d="M6.29638 10.7725C6.15272 11.0961 5.78944 11.4328 5.5 11.4995C5.21056 11.5662 4.84711 11.2217 4.75119 11.1201L1.38809 7.16901C1.29217 7.06747 1.26893 6.90513 1.32349 6.7177C1.37806 6.53027 1.50595 6.3331 1.67904 6.16957C1.85214 6.00605 2.05624 5.88955 2.24647 5.84572C2.4367 5.80189 2.59746 5.83431 2.69338 5.93585L6.29638 9.79706C6.39231 9.8986 6.55456 9.81208 6.5 9.99951C6.44544 10.1869 6.44004 10.4488 6.29638 10.7725Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_350_2667">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
