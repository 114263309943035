import React, { useState } from "react";
import "./style.css";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//components
//import SideFloatingBar from "../../Components/SideFloatingBar";
import HomeNav from "./HomeNav";
import Footer from "../../Components/Footer";

//images

import homeico1 from "../../Assets/img/home-ico-1.png";
import homeico2 from "../../Assets/img/home-ico-2.png";
import homeico3 from "../../Assets/img/home-ico-3.png";
import tmico6 from "../../Assets/img/tmico-6.png";
import homefaqsidimg from "../../Assets/img/home-faq-sidimg.png";
import com1 from "../../Assets/img/com-1.png";
import com2 from "../../Assets/img/com-2.png";
import com3 from "../../Assets/img/com-3.png";
import com4 from "../../Assets/img/com-4.png";
import com5 from "../../Assets/img/com-5.png";
import mediumIco from '../../Assets/img/medium-ico.png';

export default function Home() {
  const [sideNav, setSideNav] = useState(false);

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
    
      <div className="s_web_wrapper">
        <div className="s_hm_bg_line"></div>
        <div className="s_web_wrapper_in">
          <HomeNav setSideNav={setSideNav} sideNav={sideNav} />
          <section className=" ts_hm1_sec">
            <div className="container">
              <div className="row g-5">
                <div className="col-md-6">
                  <h1 className="srk_fw_700 ts_hm1_sec_hdng">
                    TRADE SMARTER <br />
                    WITH KUSKUSWAP
                  </h1>
                  <p className="srk srk_fs_1 srk_clr_white_1 srk_fw_300 srk_ln_1dot9 srk_mb_1dot9 ts_hm1_sec_hdng_p">
                    Maximize Your Trading Potential with Kuskuswap's AI-Driven
                    Financial Devices Social DEX, and Social Network for
                    Traders.
                  </p>
                  <div className="row srk_mb_1dot9">
                    <div className="col-6"><a href="/spot" className="ts_btn_comn w-100 ts_normal_font">
                      <img src={homeico1} className="ts_btn_comn_ico" alt="" /> Dapp</a></div>
                    <div className="col-6"><a href="http://www.pinksale.finance/launchpad/bsc/0x10FC5b0f606f71053dCBA3Ce1907B37714E3a07D" className="ts_btn_comn w-100 ts_normal_font">
                      <img src={homeico2} className="ts_btn_comn_ico" alt="" /> Buy KUS</a></div>
                  </div>
                  <div className="ts_hm1_shap1">
                    <div className="row w-100 g-5">
                      <div className="col">
                        <div className="srk srk_fs_2 srk_fw_400 srk_mb_1dot3 srk_clr_white_1 ts_normal_font">
                          Kuskuswap (KUS)
                        </div>
                        <div className="srk srk_fs_3dot5 srk_fw_600   ts_normal_font srk_clr_brightgrn">
                          Goal $250
                        </div>
                      </div>
                      <div className="col-auto mt-0">
                        <img
                          src={homeico3}
                          className="img-fluid ts_hm1_shap1_ico"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
            <div className="s_hm_botgirl"></div>
          </section>
          <section id='products' className=" ts_hm2_sec">
            <div className="container">
              <div className="ts_pg_sec_hdng_set text-center" style={{backdropFilter: 'blur(5px)'}}>
                <div className="ts_pg_sec_hdng">Products</div>
                <div className="ts_pg_sec_hdng_p"></div>
              </div>
              <div className="row g-5 mt-5">
                <div className="col-md-4">
                  <div className="ts_hm2_dv_2_out">
                    <div className="ts_hm2_dv_2">
                      <div className="ts_hm2_dv_2_in">
                       
                        <div className="srk srk_fs_1dot5 srk_clr_white_1 srk_fw_800 srk_mb_0dot5">
                        Spot Exchange with Best Price Aggregation:
                        </div>
                        <div className="srk srk_fs_0dot9 srk_clr_white_1 srk_fw_300 srk_ln_1dot6 pr65">
                        Welcome to our Spot Exchange with Best Price Aggregation, where you can trade cryptocurrencies with unparalleled ease and efficiency. Our platform leverages advanced technology to aggregate liquidity from across the crypto market, ensuring that you always get the best prices available. Whether you're buying, selling, or swapping cryptocurrencies, our Spot Exchange guarantees optimal execution and minimal slippage. Say goodbye to navigating multiple exchanges to find the best prices - with us, you'll access top-tier liquidity and competitive rates all in one place. Join the future of crypto trading with our Spot Exchange and experience seamless transactions like never before.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ts_hm2_dv_2">
                    
                    <div className="srk srk_fs_1dot5 srk_clr_white_1 srk_fw_800 srk_mb_0dot5">
                    Social Feed Integration:
                    </div>
                    <div className="srk srk_fs_0dot9 srk_clr_white_1 srk_fw_300 srk_ln_1dot6 pr65">
                    Enhance your crypto trading journey with our Social Feed Integration feature. Stay connected with fellow traders, share insights, and stay informed about the latest market trends in real-time. Our Social Feed creates a vibrant community where ideas are exchanged, strategies are discussed, and opportunities are uncovered. Whether you're a seasoned trader or a newcomer to the crypto world, our platform fosters collaboration and knowledge-sharing to help you make informed trading decisions. Join the conversation, expand your network, and elevate your trading experience with our Social Feed Integration.
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ts_hm2_dv_2">
                   
                    <div className="srk srk_fs_1dot5 srk_clr_white_1 srk_fw_800 srk_mb_0dot5">
                    Options Trading Platform with Pro and Beginner Options:
                    </div>
                    <div className="srk srk_fs_0dot9 srk_clr_white_1 srk_fw_300 srk_ln_1dot6 pr65">
                    Explore the exciting world of crypto options trading with our comprehensive Options Trading Platform. Designed to cater to traders of all skill levels, our platform offers both Pro and Beginner options to suit your individual preferences. For experienced traders seeking advanced strategies and sophisticated tools, our Pro options provide a wide range of customizable features and analytical capabilities. Meanwhile, our Beginner options offer a user-friendly interface and simplified trading experience, making it easy for newcomers to dive into the world of crypto options. Whether you're looking to hedge your portfolio, generate income, or speculate on market movements, our platform empowers you to trade with confidence and precision.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id='roadmap' className=" ts_hm3_sec">
            <div className="container">
              <div className="ts_pg_sec_hdng_set text-center" style={{backdropFilter: 'blur(5px)'}}>
                <div className="ts_pg_sec_hdng">Roadmap</div>
                <div className="ts_pg_sec_hdng_p">
                  <br /> The Largest Social Dex in The World of Defi Investors
                </div>
              </div>
              <div className="row g-0 position-relative">
                <div className="ts_hm_road_cntr_line"></div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_1">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      March 15th, 2024
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 1: Unveiling KuskuSwap Demo - Igniting Excitement
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                      Witness the grand unveiling of the KuskuSwap demo, igniting excitement and anticipation among crypto enthusiasts worldwide. Get a sneak peek into the future of decentralized trading as we showcase our groundbreaking features and visionary technology.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_2">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      May 15th, 2024
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 2: Launching the Working Product - Turning Vision into Reality
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                      Mark your calendars for the official launch of the KuskuSwap working product. Experience the culmination of months of hard work and dedication as we turn our vision into reality, providing users with a seamless and revolutionary platform for decentralized trading.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_1">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      July 15th, 2024
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 3: Building a Thriving Community - Strength in Numbers
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                      Embark on a journey to build a vibrant and engaged community around KuskuSwap. Through strategic marketing initiatives, educational content, and interactive events, we'll cultivate a passionate user base eager to participate in the evolution of our platform.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_2">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      September 15th, 2024
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 4: Enhancing User Experience - Unmatched Convenience
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                        Enter the realm of unparalleled convenience with KuskuSwap's enhanced user experience. From seamless onboarding processes to intuitive navigation and responsive support, every aspect of our platform is meticulously designed to cater to the needs of our users.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_1">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      November 15th, 2024
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 5: Unleashing Advanced Features - Pioneering Innovation
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                      Prepare to be amazed as we unleash a wave of advanced features and cutting-edge technology. From AI-powered trading algorithms to customizable trading strategies and predictive analytics, KuskuSwap empowers users to stay ahead of the curve and maximize their trading potential.


                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ts_pg_road_dv ts_pg_road_dv_bg_2">
                    <div className="ts_pg_road_dvin">
                      <div className="srk srk_fs_1dot4 srk_fw_400 srk_mb_1 srk_clr_white_1 text-center">
                      January 15th, 2025
                      </div>
                      <div className="srk srk_fs_2dot4 srk_fw_800 srk_mb_0dot5 srk_clr_white_1 ">
                      Step 6: Scaling Up - Global Expansion
                      </div>
                      <div className="srk srk_fs_1dot2 srk_fw_300  srk_clr_white_0dot8 srk_ln_1dot4">
                      Witness the rapid expansion of KuskuSwap as we set our sights on global markets. Through strategic partnerships, localized initiatives, and targeted outreach efforts, we'll establish KuskuSwap as the go-to destination for crypto enthusiasts worldwide.

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" ts_hm4_sec">
            <div className="container">
              <div className="row g-5">
                <div className="col-md-5">
                  <div className="ts_pg_sec_hdng_set" style={{backdropFilter: 'blur(5px)'}}>
                    <div id='faq' className="ts_pg_sec_hdng">
                      Frequently Asked Questions
                    </div>
                    <div className="ts_pg_sec_hdng_p">
                    Discover the answers to commonly asked questions about KuskuSwap, the innovative decentralized exchange platform revolutionizing the crypto market. Learn about its features, security measures, and how you can get involved in this dynamic ecosystem.

</div>
                  </div>
                  <img src={homefaqsidimg} className="img-fluid" alt="" />
                </div>
                <div className="col-md-7">
                  <div>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography>What is KuskuSwap?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        KuskuSwap is a decentralized exchange (DEX) platform built on blockchain technology that allows users to trade cryptocurrencies directly with each other without the need for intermediaries. It offers innovative features such as liquidity aggregation, social trading, and options trading, revolutionizing the way people engage with digital assets.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography>How does KuskuSwap ensure liquidity across markets?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        KuskuSwap leverages advanced algorithms to aggregate liquidity from various sources across the crypto market. By pooling liquidity from multiple decentralized and centralized exchanges, KuskuSwap ensures that users always have access to the best prices and optimal liquidity for their trades.

                        </Typography>
            
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography>What sets KuskuSwap apart from other decentralized exchanges?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        KuskuSwap distinguishes itself through its focus on user experience, innovative features, and community engagement. With features like social trading, customizable options trading, and a vibrant user community, KuskuSwap offers a unique and dynamic trading experience that sets it apart from other DEX platforms.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography>Is KuskuSwap safe and secure to use? </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        Yes, KuskuSwap prioritizes the security and integrity of user funds and data. The platform employs robust security measures such as encryption, multi-signature wallets, and smart contract audits to safeguard user assets and ensure a secure trading environment. Additionally, users have full control over their funds, as KuskuSwap does not hold custody of user assets.
                        </Typography>
                        
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                      >
                        <Typography>How can I get involved with KuskuSwap?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                        Getting involved with KuskuSwap is not just about trading; it's about becoming an integral part of a dynamic and thriving community that is shaping the future of decentralized finance (DeFi). Here's how you can dive in:
                        </Typography>
                        <Typography>
                          1. Invest in the Project
                          </Typography>
                          <Typography>
                            2. Join the Community
                            </Typography>
                            <Typography>
                        3. Provide Liquidity
                        </Typography>
                        <Typography>
                          4. Spread the Word
                        </Typography>
                        <Typography>
                         5. Contribute to Development
                        </Typography>
                      
                    
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id='tokenomics' className=" ts_hm5_sec">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="ts_pg_sec_hdng_set text-center">
                    <div className="ts_pg_sec_hdng">Tokenomics Information</div>
                    <div className="ts_pg_sec_hdng_p">
                    KuskuSwap prioritizes transparency. Our tokenomics plan ensures clarity on supply, distribution, and utility, empowering informed decisions in the DeFi space.{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center text-center srk_mt_1dot5">
                <div className="col-md-3 col-6">
                  <div className="srk srk_fs_1 srk_fw_300 srk_clr_white_0dot9 srk_mb_1">
                    Token Name
                  </div>
                  <div className="srk srk_fs_3 srk_fw_800 srk_clr_white_1 srk_mb_1dot5">
                    KuskuSwap
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="srk srk_fs_1 srk_fw_300 srk_clr_white_0dot9 srk_mb_1">
                    Token Symbol
                  </div>
                  <div className="srk srk_fs_3 srk_fw_800 srk_clr_white_1 srk_mb_1dot5">
                    KUS
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="srk srk_fs_1 srk_fw_300 srk_clr_white_0dot9 srk_mb_1">
                    Token Amount
                  </div>
                  <div className="srk srk_fs_2dot5 srk_fw_800 srk_clr_white_1 srk_mb_1dot5">
                    400k
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="srk srk_fs_1 srk_fw_300 srk_clr_white_0dot9 srk_mb_1">
                    Blockchain
                  </div>
                  <div className="srk srk_fs_3 srk_fw_800 srk_clr_white_1 srk_mb_1dot5">
                    BSC
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" ts_hm7_sec">
            <div className="container">
              <div className="row h-md-100">
                <div className="col-md-7">
                  <div className="sk_pie_chart_out_div">
                    <div className="sk_pie_chart_out">
                      <div className="sk_pie_chart_in_ring"></div>
                      <div className="sk_pie_chart_in">
                        <div className="sk_pie_chart_in_h1">Total Supply</div>
                        <div className="sk_pie_chart_in_h2">
                          400k Tokens
                        </div>
                      </div>
                      <div className="sk_pie_chart_lbls">
                        <div className="sk_pie_chart_lbls_in skpchls-1">
                          2.5<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">Team</div>
                        </div>
                        <div className="sk_pie_chart_lbls_in skpchls-2">
                          75<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">
                            UNCX
                          </div>
                        </div>
                        <div className="sk_pie_chart_lbls_in skpchls-3">
                          5<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">
                            Liquidity
                          </div>
                        </div>
                        <div className="sk_pie_chart_lbls_in skpchls-4">
                          12.5<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">
                            Community
                          </div>
                        </div>
                        <div className="sk_pie_chart_lbls_in skpchls-5">
                          2.5<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">
                            Company Reserves
                          </div>
                        </div>
                        <div className="sk_pie_chart_lbls_in skpchls-6">
                          2.5<span>%</span>
                          <div className="sk_pie_chart_lbls_in_l">
                            Marketing
                          </div>
                        </div>
                      </div>
                      <div id="chartdiv"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="sk_chart_sid_dv_totout">
                    <div className="sk_chart_sid_dv mt-md-0">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            75%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                              UNCX
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            300,000
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sk_chart_sid_dv">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            5%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                              Liquidity
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            20,000
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sk_chart_sid_dv">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            12.5%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                              Community
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            50,000
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sk_chart_sid_dv">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            2.5%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                              Team
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            10,000
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sk_chart_sid_dv">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            2.5%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                            Marketing
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            10,000
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sk_chart_sid_dv">
                      <div className="row landingTeamBox">
                        <div className="col-md-7 col-6">
                          <div className="srk srk_fs_1dot2 srk_fw_600 srk_clr_bright_green srk_mb_0dot5">
                            2.5%{" "}
                            <span className="srk_fs_1dot2 srk_ml_0dot6 sk_chart_sid_dv_1">
                              Company Reserves
                            </span>
                          </div>
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  ">
                            Token Amount
                          </div>
                        </div>
                        <div className="col-md-5 col-6 text-end">
                          <div className="srk srk_fs_0dot9 srk_clr_white_07 srk_fw_400  srk_mb_0dot5">
                            Tokens
                          </div>
                          <div className="srk srk_fs_1dot1 srk_fw_400 srk_clr_white_1 ">
                            10,000
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" ts_hm6_sec">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="ts_pg_sec_hdng_set text-center">
                    <div id='community' className="ts_pg_sec_hdng">Community</div>
                    <div className="ts_pg_sec_hdng_p">Join Us At</div>
                  </div>
                </div>
              </div>
              <div className="ts_hm_com_set">
                <div className="row justify-content-center text-center srk_mt_1dot5">
              
                  <div className="col-md col-6">
                    <a href="https://medium.com/@kuskusswap">
                      <img src={mediumIco} className="ts_hm_com_ico" alt="" />
                      <div className="srk srk_fs_1 srk_fw_400 srk_clr_white_1 srk_mb_1">
                        Medium
                      </div>
                    </a>
                  </div>
                  <div className="col-md col-6">
                    <a href="https://t.me/kuskuswap">
                      <img src={com3} className="ts_hm_com_ico" alt="" />
                      <div className="srk srk_fs_1 srk_fw_400 srk_clr_white_1 srk_mb_1">
                        Telegram
                      </div>
                    </a>
                  </div>
                  <div className="col-md col-6">
                    <a href="https://twitter.com/kuskusswap">
                      <img src={com4} className="ts_hm_com_ico" alt="" />
                      <div className="srk srk_fs_1 srk_fw_400 srk_clr_white_1 srk_mb_1">
                        Twitter
                      </div>
                    </a>
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
}
