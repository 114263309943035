import React from "react";
import "./style.css";

//images
import Logo from "../../Assets/img/logo.png";
import ftr1 from "../../Assets/img/ftr-1.png";
import ftr2 from "../../Assets/img/ftr-2.png";
import ftr3 from "../../Assets/img/ftr-3.png";
import mediumFtr from '../../Assets/img/medium-ftr.png';

export default function Footer() {
  return (
    <>
      <footer className="ts_ftr_sec">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-4">
              <a href="#" className="">
                <img src={Logo} className="ts_ftr_sec_logo" alt="" />
              </a>
              <p className="srk srk_fs_1 srk_fw_400 ts_ftr_sec_logo_p srk_ln_1dot6 srk_clr_white_1 srk_mb_1dot5">
              KuskuSwap: Revolutionizing Decentralized Finance. Join our vibrant community and experience seamless crypto trading with innovative features, unparalleled liquidity aggregation, and built-in chat for real-time interaction with fellow traders. Dive into the future of finance today!
              </p>
              <div className="ts_ftr_scico">
                <a href="https://t.me/kuskuswap" className="ts_ftr_sico_a">
                  <img src={ftr1} className="ts_ftr_sico" alt="" />
                </a>
                <a href="https://medium.com/@kuskusswap" className="ts_ftr_sico_a">
                  <img src={mediumFtr} className="ts_ftr_sico" alt="" />
                </a>
                <a href="https://twitter.com/kuskusswap" className="ts_ftr_sico_a">
                  <img src={ftr3} className="ts_ftr_sico" alt="" />
                </a>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-7">
              <div className="row g-5">
                <div className="col-md-4">
                  <div className="ts_ftr_sec_hd">Site Map</div>
                  <a href="#products" className="ts_ftr_sec_li">
                    Products
                  </a>
                  <a href="#faq" className="ts_ftr_sec_li">
                    FAQ’s
                  </a>
                  <a href="#tokenomics" className="ts_ftr_sec_li">
                    Tokenomics
                  </a>
                  <a href="#roadmap" className="ts_ftr_sec_li">
                    Road map
                  </a>
                  <a href="#community" className="ts_ftr_sec_li">
                    Community
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="ts_ftr_sec_hd">Company</div>
                  <a href="https://t.me/kuskuswap" className="ts_ftr_sec_li">
                    Help & Support
                  </a>
                  <a href="#" className="ts_ftr_sec_li">
                    Terms & Conditions
                  </a>
                  <a href="#" className="ts_ftr_sec_li">
                    Privacy Policy
                  </a>
                </div>
                <div className="col-md-4">
                  <div className="ts_ftr_sec_hd">Resource</div>
                  <a href="https://t.me/Adammillerx" className="ts_ftr_sec_li">
                    Partnership
                  </a>
                  <a href="https://www.reddit.com/r/kuskusswap" className="ts_ftr_sec_li">
                    Blog
                  </a>
                  <a href="https://twitter.com/kuskusswap" className="ts_ftr_sec_li">
                    Announcement
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-md-8 d-flex align-items-center">
              <div className="ts_ftr_sec_cpy">
                Copyright <span className="srk_clr_brightgrn">KuskuSwap</span>{" "}
                core 2024 All right reserved
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <a href="https://www.pinksale.finance" className="mr-15">
                <img src="https://docs.pinksale.finance/~gitbook/image?url=https:%2F%2F191452618-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252F-MdJrJV-Z8jLCR-JNtlx%252Fuploads%252FiEWLEOpo5X60d3INULja%252Fpinksale-2000x1000-transparent.png%3Falt=media%26token=a4c9a802-f97b-44ac-9d1b-efa7228cf719&width=768&dpr=2&quality=100&sign=3ea28c59a614579e628470adea5e7a60b2db0ed199a5b13e520932b6bc20f3eb" alt="PinkSale logo"
                  width='150px'
                />
              </a>

              <a href="https://www.dexview.com/">
                <img src="https://dexview.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fabb35737-bbc2-482d-a8dd-5e0f0f013c9c%2Fdexview-white-text-transparent.png?table=block&id=66a5b7d6-5361-41bd-a09e-6b8565589d77&spaceId=f2f78fd3-79ff-4ce0-bc9c-ba15ee5eba0f&width=2000&userId=&cache=v2" alt="dexview logo" 
                  width='150px'
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}